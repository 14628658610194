import React, { useState, useEffect } from 'react';
import { apiAnalyseDaylog, apiAskMrFit, apiAnalyseWeek } from '../api';
import '../styles/MrFitAdvisor.css';

const MrFitAdvisor = ({ date, dayLog, totalMacros, macrosGoals }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const email = user.email;
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dailyAnalysis, setDailyAnalysis] = useState({
    improvements: []
  });
  const [weeklyAnalysis, setWeeklyAnalysis] = useState(null);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
  const [isWeeklyAnalysisLoading, setIsWeeklyAnalysisLoading] = useState(false);

  useEffect(() => {
    const fetchAnalysis = async () => {
      setIsAnalysisLoading(true);
      setIsWeeklyAnalysisLoading(true);
      try {
        // Obtener análisis semanal
        const weekAnalysis = await apiAnalyseWeek(email, date);
        setWeeklyAnalysis(weekAnalysis);

        // Obtener análisis diario
        const analysis = await apiAnalyseDaylog(email, date, totalMacros, macrosGoals);
        setDailyAnalysis(analysis);
      } catch (error) {
        console.error('Error getting analysis:', error);
        setDailyAnalysis({
          improvements: []
        });
      } finally {
        setIsAnalysisLoading(false);
        setIsWeeklyAnalysisLoading(false);
      }
    };

    if (dayLog && totalMacros && macrosGoals) {
      fetchAnalysis();
    }
  }, [dayLog, totalMacros, macrosGoals, email, date]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setIsLoading(true);
    try {
      const answer = await apiAskMrFit(email, dayLog, totalMacros, macrosGoals, input);
      setResponse(answer);
      setInput('');
    } catch (error) {
      console.error('Error asking misterfit:', error);
      setResponse('Sorry, I could not process your question.');
    } finally {
      setIsLoading(false);
    }
  };

  const getFoodGroupIcon = (groupName) => {
    const icons = {
      'Vegetables': 'fa-carrot',
      'Fruit': 'fa-apple-alt',
      'Whole Grains': 'fa-bread-slice',
      'Dairy or Alternatives': 'fa-cheese',
      'Olive Oil': 'fa-oil-can',
      'Legumes': 'fa-seedling',
      'Fish/White Meat/Eggs': 'fa-fish',
      'Occasionally': 'fa-cookie-bite'
    };
    return icons[groupName] || 'fa-utensils';
  };

  const renderFoodGroup = (title, group) => {
    if (!group) return null;

    return (
      <div className="food-group mb-2">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <h6 className="mb-0">
              <i className={`fas ${getFoodGroupIcon(title)} me-2`}></i>
              {title}
            </h6>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <span className="metric-label">
              <i className={`fas fa-check-circle ${group.quantity ? 'text-success' : 'text-danger'}`}></i>
              {' '}Cantidad
            </span>
          </div>
          {group.variety !== undefined && (
            <div className="col-md-3 d-none d-md-block">
              <span className="metric-label">
                <i className={`fas fa-check-circle ${group.variety ? 'text-success' : 'text-danger'}`}></i>
                {' '}Variedad
              </span>
            </div>
          )}
        </div>
        {group.comment && (
          <div className="row">
            <div className="col-12">
              <p className="mb-0 mt-2">{group.comment}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getGroupsWithIssues = (analysis) => {
    if (!analysis) return { bothIssues: [], oneIssue: [], noIssues: [] };

    const groups = [
      { name: 'Vegetables', data: analysis.vegetables },
      { name: 'Fruit', data: analysis.daily_fruit },
      { name: 'Whole Grains', data: analysis.whole_grains },
      { name: 'Dairy or Alternatives', data: analysis.dairy_or_alternatives },
      { name: 'Olive Oil', data: analysis.olive_oil },
      { name: 'Legumes', data: analysis.legumes },
      { name: 'Fish/White Meat/Eggs', data: analysis.fish_white_meat_eggs },
      { name: 'Occasionally', data: analysis.occasionally }
    ];

    const bothIssues = [];
    const oneIssue = [];
    const noIssues = [];

    groups.forEach(group => {
      if (!group.data) return;

      const hasVarietyMetric = group.data.variety !== undefined;
      const issues = (hasVarietyMetric ? !group.data.variety : false) + !group.data.quantity;

      if (issues === 2 || (!hasVarietyMetric && issues === 1)) {
        bothIssues.push(group);
      } else if (issues === 1) {
        oneIssue.push(group);
      } else {
        noIssues.push(group);
      }
    });

    return { bothIssues, oneIssue, noIssues };
  };

  return (
    <div className="mr-fit-container mt-5">
      {(isAnalysisLoading || isWeeklyAnalysisLoading) ? (
        <div className="daily-analysis mb-4">
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Análisis Diario */}
          <div className="daily-analysis mb-4">
            <h4>Tips for Today</h4>
            {dailyAnalysis && (
              <div className="p-3 rounded">
                {dailyAnalysis.positive && dailyAnalysis.positive.length > 0 && (
                  <div className="food-groups-section mb-4">
                    <h5 className="text-success mb-3">Lo que haces bien</h5>
                    <ul className="list-unstyled">
                      {dailyAnalysis.positive.map((punto, index) => (
                        <li key={index} className="mb-2">
                          <i className="fas fa-plus-circle me-2 text-success"></i>
                          <span className="daily-comment">{punto}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                
                {dailyAnalysis.improvements && dailyAnalysis.improvements.length > 0 && (
                  <div className="food-groups-section">
                    <h5 className="text-primary mb-3">Sugerencias de mejora</h5>
                    <ul className="list-unstyled">
                      {dailyAnalysis.improvements.map((punto, index) => (
                        <li key={index} className="mb-2">
                          <i className="fas fa-lightbulb me-2 text-primary"></i>
                          <span className="daily-comment">{punto}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Análisis Semanal */}
          {weeklyAnalysis && (
            <div className="daily-analysis mb-4">
              <h4>Weekly Analysis</h4>
              <div className="p-3 rounded">
                {/* Grupos con ambos problemas */}
                {getGroupsWithIssues(weeklyAnalysis).bothIssues.length > 0 && (
                  <div className="food-groups-section mb-4">
                    <h5 className="text-danger mb-3">Need more attention</h5>
                    {getGroupsWithIssues(weeklyAnalysis).bothIssues.map(group => 
                      renderFoodGroup(group.name, group.data)
                    )}
                  </div>
                )}

                {/* Grupos con un problema */}
                {getGroupsWithIssues(weeklyAnalysis).oneIssue.length > 0 && (
                  <div className="food-groups-section mb-4">
                    <h5 className="text-warning mb-3">Can improve</h5>
                    {getGroupsWithIssues(weeklyAnalysis).oneIssue.map(group => 
                      renderFoodGroup(group.name, group.data)
                    )}
                  </div>
                )}

                {/* Grupos sin problemas */}
                {getGroupsWithIssues(weeklyAnalysis).noIssues.length > 0 && (
                  <div className="food-groups-section mb-4">
                    <h5 className="text-success mb-3">¡Very good!</h5>
                    {getGroupsWithIssues(weeklyAnalysis).noIssues.map(group => 
                      renderFoodGroup(group.name, group.data)
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Ask Mr. Fit */}
          <div className="daily-analysis mb-4">
            <h4>Ask misterfit.ai</h4>
            <form onSubmit={handleSubmit} className="mb-3">
              <div className="input-group">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className="form-control"
                  placeholder="Ask me anything about your diet..."
                  disabled={isLoading}
                />
                <button 
                  type="submit" 
                  className="btn btn-icon" 
                  disabled={isLoading}
                  title="Send question"
                >
                  {isLoading ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : (
                    <i className="fas fa-paper-plane"></i>
                  )}
                </button>
              </div>
            </form>
          

            {response && (
              <div className="response-container mt-3 p-3">
                <p className="mb-0">{response}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MrFitAdvisor; 