import React, { useEffect, useState } from 'react';
import { 
  apiGetFavoriteMeals, 
  apiDeleteFavoriteMeal, 
  apiLogFavoriteMeal, 
  apiUpdateFavoriteMealEntry, 
  apiUpdateFavoriteMealAlias 
} from '../api';

const MyFavoriteMeals = ({ email }) => {
  const [favoriteMeals, setFavoriteMeals] = useState([]);

  useEffect(() => {
    const getFavoriteMeals = async () => {
      try {
        const data = await apiGetFavoriteMeals(email);
        console.log('Favorite meals data:', data);
        setFavoriteMeals(data);
      } catch (error) {
        console.error('Error fetching favorite meals:', error);
      }
    };
    getFavoriteMeals();
  }, [email]);

  const handleDelete = async (id) => {
    try {
      await apiDeleteFavoriteMeal(email, id);
      setFavoriteMeals(favoriteMeals.filter(meal => meal.id !== id));
    } catch (error) {
      console.error('Error deleting favorite meal:', error);
      alert('Failed to delete favorite meal. Please try again.');
    }
  };

  const handleLogMeal = async (mealId) => {
    try {
      const date = new Date().toISOString().split('T')[0];
      await apiLogFavoriteMeal(email, mealId, date, 'other');
      alert('Meal logged successfully!');
    } catch (error) {
      console.error('Error logging favorite meal:', error);
      alert('Failed to log favorite meal. Please try again.');
    }
  };

  const handleEditEntryClick = async (mealId, entryId, currentQuantity, foodName, units) => {
    const newQuantity = prompt(`Enter new quantity for ${foodName}:`, currentQuantity);
    if (newQuantity !== null && !isNaN(newQuantity)) {
      try {
        const updatedQuantity = parseFloat(newQuantity);
        const updatedEntry = await apiUpdateFavoriteMealEntry(email, mealId, entryId, updatedQuantity);
        
        setFavoriteMeals(prevMeals => 
          prevMeals.map(meal => 
            meal.id === mealId 
              ? {
                  ...meal,
                  entries: meal.entries.map(entry =>
                    entry.id === entryId ? updatedEntry : entry
                  )
                }
              : meal
          )
        );
      } catch (error) {
        console.error('Error updating favorite meal entry:', error);
        alert('Failed to update favorite meal entry. Please try again.');
      }
    }
  };

  const handleEditAlias = async (mealId, currentAlias) => {
    const newAlias = prompt(`Enter new name for "${currentAlias}":`, currentAlias);
    if (newAlias && newAlias !== currentAlias) {
      try {
        const updatedMeal = await apiUpdateFavoriteMealAlias(email, mealId, newAlias);
        setFavoriteMeals(prevMeals => 
          prevMeals.map(meal => 
            meal.id === mealId ? { ...meal, alias: updatedMeal.alias } : meal
          )
        );
      } catch (error) {
        console.error('Error updating favorite meal alias:', error);
        alert('Failed to update favorite meal name. Please try again.');
      }
    }
  };

  const getTotalMacrosForMeal = (entries) => {
    return entries.reduce((total, entry) => {
      total.calories += entry.macros.calories;
      total.proteins += entry.macros.proteins;
      total.carbohydrates += entry.macros.carbohydrates;
      total.fats += entry.macros.fats;
      return total;
    }, { calories: 0, proteins: 0, carbohydrates: 0, fats: 0 });
  };

  return (
    <div className="container my-favorite-meals-container mt-5">
      <div className="row header-row">
        <div className="col-md-6 col-sm-12"></div>
        <div className="col-md-1 col-sm-6">calories</div>
        <div className="col-md-1 col-sm-6">proteins</div>
        <div className="col-md-1 col-sm-6">carbs</div>
        <div className="col-md-1 col-sm-6">fats</div>
        <div className="col-md-1 col-sm-12"></div>
      </div>
      {favoriteMeals.map((meal) => (
        <div key={meal.id} className="meal-section mb-4">
          <div className="row meal-header">
            <div className="col-md-6">
              <h3 
                onClick={() => handleEditAlias(meal.id, meal.alias)}
                style={{cursor: 'pointer'}}
              >
                {meal.alias} <i className="fas fa-edit" style={{fontSize: '0.8em'}}></i>
              </h3>
            </div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).calories)}</div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).proteins)}</div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).carbohydrates)}</div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).fats)}</div>
            <div className="col-md-1 col-sm-6 action-column">
              <button onClick={() => handleDelete(meal.id)} className="btn btn-sm btn-link action-button" title="Delete">
                <i className="fas fa-trash action-icon"></i>
              </button>
            </div>
          </div>
          {meal.entries.map((entry) => (
            <div key={entry.id} className="row meal-row align-items-center">
              <div 
                className="col-md-6 col-sm-12"
                onClick={() => handleEditEntryClick(meal.id, entry.id, entry.quantity, entry.food.name, entry.units)}
                style={{cursor: 'pointer'}}
              >
                {entry.food.name} ({entry.quantity} {entry.units})
              </div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.calories)}</div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.proteins)}</div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.carbohydrates)}</div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.fats)}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MyFavoriteMeals;