// MyFood.js
import React, { useEffect, useState } from 'react';
import { apiFetchFoodInfo, apiDeleteFoodInfo, apiUpdateFoodInfo } from '../api';
import '../styles/MyFood.css';

const MyFood = ({ email }) => {
  const [foodInfoList, setfoodInfoList] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [editingFood, setEditingFood] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const loadfoodInfoList = async () => {
    try {
      const data = await apiFetchFoodInfo(email);
      const foodsWithShowSynonyms = data.map(food => ({
        ...food,
        showSynonyms: false
      }));
      setfoodInfoList(foodsWithShowSynonyms);
      console.log(`apiFetchFoodInfo called`);
      console.log(data);
    } catch (error) {
      console.error('Error fetching known foods:', error);
    }
  };


  useEffect(() => {
    loadfoodInfoList();
  }, [email, updateTrigger]);


  const sortBy = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    setfoodInfoList(foodInfoList.sort((a, b) => {
      if (key === 'name' || key === 'source') {
        return direction === 'ascending' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      } else {
        return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
      }
    }));
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '↕';
  };

  const formatSource = (source) => {
    if (source.toLowerCase().startsWith('http')) {
      return (
        <a href={source} target="_blank" rel="noopener noreferrer" className="ofd-link">
          OFD
        </a>
      );
    }
    return source;
  };

  const handleDelete = async (id) => {
    try {
      console.log(`apiDeleteFoodInfo called with id: ${id}`);
      const response = await apiDeleteFoodInfo(email, id, false);
      if (response.requireConfirmation) {
        setConfirmDelete({id, message: response.message});
      } else if (response.success) {
        setfoodInfoList(prevFoods => [...prevFoods.filter(food => food.id !== id)]);
        setUpdateTrigger(prev => prev + 1);
      } else {
        alert(response.message || 'Failed to delete food. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting food:', error);
      alert('Failed to delete food. Please try again.');
    }
  };

  const handleConfirmDelete = async () => {
    if (confirmDelete) {
      try {
        const response = await apiDeleteFoodInfo(email, confirmDelete.id, true);
        if (response.success) {
          setfoodInfoList(prevFoods => [...prevFoods.filter(food => food.id !== confirmDelete.id)]);
          setConfirmDelete(null);
          setUpdateTrigger(prev => prev + 1); // TODO: check if this is needed
        } else {
          alert(response.message || 'Failed to delete food. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting food:', error);
        alert('Failed to delete food. Please try again.');
      }
    }
  };


  const handleEdit = (food) => {
    setEditingFood({ ...food, originalSource: food.source });
  };

  const handleSave = async () => {
    try {
      const { originalSource, ...updatedData } = editingFood;
      
      const validUpdatedData = {
        serving_quantity: updatedData.serving_quantity,
        source: updatedData.source,
        proteins_100g: updatedData.proteins_100g,
        carbohydrates_100g: updatedData.carbohydrates_100g,
        fats_100g: updatedData.fats_100g
      };
  
      if (originalSource !== 'user') {
        validUpdatedData.source = 'user';
      }
  
      await apiUpdateFoodInfo(email, editingFood.id, validUpdatedData);
      setfoodInfoList(foodInfoList.map(food => food.id === editingFood.id ? { ...food, ...validUpdatedData, calories_100g: calculateCalories(validUpdatedData) } : food));
      setEditingFood(null);
    } catch (error) {
      console.error('Error updating food:', error);
      alert('Failed to update food. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingFood({ ...editingFood, [name]: value });
  };

  const calculateCalories = (macros) => {
    return 4 * macros.proteins_100g + 4 * macros.carbohydrates_100g + 9 * macros.fats_100g;
  };

  const calculateServingMacros = (food) => {
    const factor = food.serving_quantity / 100;
    return {
      calories_serving: Math.round(food.calories_100g * factor),
      proteins_serving: Math.round(food.proteins_100g * factor),
      carbohydrates_serving: Math.round(food.carbohydrates_100g * factor),
      fats_serving: Math.round(food.fats_100g * factor)
    };
  };

  return (
    <div className="container my-food-container mt-5">
      {confirmDelete && (
        <div className="alert alert-warning">
          <p>{confirmDelete.message}</p>
          <button onClick={handleConfirmDelete} className="btn btn-danger mr-2">Confirmar eliminación</button>
          <button onClick={() => setConfirmDelete(null)} className="btn btn-secondary">Cancelar</button>
        </div>
      )}
      <div className="row header-row">
        <div className="col-md-3 col-sm-12">Food Name <button onClick={() => sortBy('name')} className="sort-btn">{getSortIcon('name')}</button></div>
        <div className="col-md-1 col-sm-6">Qty (g)</div>
        <div className="col-md-1 col-sm-6">Calories <button onClick={() => sortBy('calories_100g')} className="sort-btn">{getSortIcon('calories_100g')}</button></div>
        <div className="col-md-1 col-sm-6">Proteins <button onClick={() => sortBy('proteins_100g')} className="sort-btn">{getSortIcon('proteins_100g')}</button></div>
        <div className="col-md-1 col-sm-6">Carbs <button onClick={() => sortBy('carbohydrates_100g')} className="sort-btn">{getSortIcon('carbohydrates_100g')}</button></div>
        <div className="col-md-1 col-sm-6">Fats <button onClick={() => sortBy('fats_100g')} className="sort-btn">{getSortIcon('fats_100g')}</button></div>
        <div className="col-md-1 col-sm-6">Source <button onClick={() => sortBy('source')} className="sort-btn">{getSortIcon('source')}</button></div>
        <div className="col-md-1 col-sm-12">Freq. <button onClick={() => sortBy('frequency')} className="sort-btn">{getSortIcon('frequency')}</button></div>
        <div className="col-md-1 col-sm-12">% cal. <button onClick={() => sortBy('calorie_percentage')} className="sort-btn">{getSortIcon('calorie_percentage')}</button></div>
      </div>
      {foodInfoList.map((food) => (
        <React.Fragment key={food.id}>
          <div className="row food-row align-items-center">
            {editingFood && editingFood.id === food.id ? (
              <>
                <div className="col-md-3 col-sm-12 text-left"><strong>{food.name}</strong></div>
                <div className="col-md-1 col-sm-6 text-center">100</div>
                <div className="col-md-1 col-sm-6 text-center">{Math.round(calculateCalories(editingFood))}</div>
                <div className="col-md-1 col-sm-6 text-center">
                  <input name="proteins_100g" value={editingFood.proteins_100g} onChange={handleInputChange} className="form-control form-control-sm" />
                </div>
                <div className="col-md-1 col-sm-6 text-center">
                  <input name="carbohydrates_100g" value={editingFood.carbohydrates_100g} onChange={handleInputChange} className="form-control form-control-sm" />
                </div>
                <div className="col-md-1 col-sm-6 text-center">
                  <input name="fats_100g" value={editingFood.fats_100g} onChange={handleInputChange} className="form-control form-control-sm" />
                </div>
                <div className="col-md-1 col-sm-6 text-center">{editingFood.source}</div>
                <div className="col-md-1 col-sm-6 text-center">{editingFood.frequency}</div>
                <div className="col-md-1 col-sm-6 text-center">{editingFood.calorie_percentage.toFixed(1)}%</div>
                <div className="col-md-1 col-sm-6 text-center">
                  <button onClick={handleSave} className="btn btn-sm btn-link action-button">
                    <i className="fas fa-check action-icon"></i>
                  </button>
                  <button onClick={() => setEditingFood(null)} className="btn btn-sm btn-link action-button">
                    <i className="fas fa-times action-icon"></i>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-3 col-sm-12 text-left">
                  <strong>{food.name}</strong>
                  {food.synonyms && food.synonyms.length > 1 && (
                    <button 
                      className="synonym-toggle"
                      onClick={() => {
                        setfoodInfoList(foods => foods.map(f => 
                          f.id === food.id 
                            ? {...f, showSynonyms: !f.showSynonyms} 
                            : f
                        ))
                      }}
                    >
                      {food.showSynonyms ? '−' : '+'}
                    </button>
                  )}
                </div>
                <div className="col-md-1 col-sm-6 text-center">100</div>
                <div className="col-md-1 col-sm-6 text-center">{Math.round(food.calories_100g)}</div>
                <div className="col-md-1 col-sm-6 text-center">{Math.round(food.proteins_100g)}</div>
                <div className="col-md-1 col-sm-6 text-center">{Math.round(food.carbohydrates_100g)}</div>
                <div className="col-md-1 col-sm-6 text-center">{Math.round(food.fats_100g)}</div>
                <div className="col-md-1 col-sm-6 text-center">{formatSource(food.source)}</div>
                <div className="col-md-1 col-sm-6 text-center">{food.frequency}</div>
                <div className="col-md-1 col-sm-6 text-center">{food.calorie_percentage.toFixed(1)}%</div>
                <div className="col-md-1 col-sm-6 text-center">
                  <button className="btn btn-sm btn-link action-button" onClick={() => handleEdit(food)}>
                    <i className="fas fa-edit action-icon"></i>
                  </button>
                  <button className="btn btn-sm btn-link action-button" onClick={() => handleDelete(food.id)}>
                    <i className="fas fa-trash action-icon"></i>
                  </button>
                </div>
              </>
            )}
          </div>

          {/* Fila de sinónimos */}
          {!editingFood && food.showSynonyms && food.synonyms && food.synonyms.length > 1 && (
            <div className="row food-row">
              <div className="col-12 offset-md-1">
                {food.synonyms.map(([synonym, count], index) => (
                  <span key={index}>
                    {synonym} ({count})
                    {index < food.synonyms.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Fila de serving */}
          {!editingFood || editingFood.id !== food.id ? (
            <div className="row food-row align-items-center">
              <div className="col-md-3 col-sm-12 text-left pl-5 ml-3">_serving</div>
              <div className="col-md-1 col-sm-6 text-center">{food.serving_quantity}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(food).calories_serving}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(food).proteins_serving}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(food).carbohydrates_serving}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(food).fats_serving}</div>
              <div className="col-md-2 col-sm-6"></div>
            </div>
          ) : (
            <div className="row food-row align-items-center">
              <div className="col-md-3 col-sm-12 text-left pl-5 ml-3">_serving</div>
              <div className="col-md-1 col-sm-6 text-center">
                <input 
                  name="serving_quantity" 
                  value={editingFood.serving_quantity} 
                  onChange={handleInputChange} 
                  className="form-control form-control-sm" 
                />
              </div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(editingFood).calories_serving}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(editingFood).proteins_serving}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(editingFood).carbohydrates_serving}</div>
              <div className="col-md-1 col-sm-6 text-center">{calculateServingMacros(editingFood).fats_serving}</div>
              <div className="col-md-2 col-sm-6"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MyFood;