import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiSignUp } from '../api';
function SignUp() {
  const [formData, setFormData] = useState({ name: '', email: '', password: '' });
  const navigate = useNavigate();

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const response = await apiSignUp(
      formData
    );
    const data = await response.json();
    if (response.ok) {
      alert('User registered successfully!');
      navigate('/login');
    } else {
      alert(data.error);
    }
  };

  return (
    <form onSubmit={handleSignUp} className="mt-5 mb-3">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">Name</label>
        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleFormDataChange} required />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email</label>
        <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleFormDataChange} required />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">Password</label>
        <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleFormDataChange} required />
      </div>
      <button type="submit" className="btn btn-primary">Sign Up</button>
    </form>
  );
}

export default SignUp;
