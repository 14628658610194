import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import landingImageLarge from '../landing-image-large.png';
import landingImageSmall from '../landing-image-small.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 mb-4 mb-lg-0">
            <div className="landing-text">
              <p className="lead">Your AI assistant for an effortless health.</p>
              <Link to="/signup" className="btn btn-primary btn-lg">Sign Up</Link>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="landing-image">
              <img src={landingImageLarge} alt="MacroTracker AI" className="img-fluid d-none d-xxl-block" />
              <img src={landingImageSmall} alt="MacroTracker AI" className="img-fluid d-xxl-none" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;