import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ManualEntryModal = ({ show, handleClose, mealType, onSubmit }) => {
  const [entryData, setEntryData] = useState({
    name: '',
    macros: { proteins: 0, carbohydrates: 0, fats: 0 },
    quantity: 0,
    units: 'g'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in entryData.macros) {
      setEntryData(prev => ({
        ...prev,
        macros: { ...prev.macros, [name]: parseFloat(value) || 0 }
      }));
    } else {
      setEntryData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(mealType, entryData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Añadir entrada manual para {mealType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre del alimento</Form.Label>
            <Form.Control type="text" name="name" value={entryData.name} onChange={handleChange} placeholder="Introduce el nombre del alimento" required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Proteínas (g)</Form.Label>
            <Form.Control type="number" name="proteins" value={entryData.macros.proteins} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Carbohidratos (g)</Form.Label>
            <Form.Control type="number" name="carbohydrates" value={entryData.macros.carbohydrates} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Grasas (g)</Form.Label>
            <Form.Control type="number" name="fats" value={entryData.macros.fats} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Cantidad</Form.Label>
            <Form.Control type="number" name="quantity" value={entryData.quantity} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Unidades</Form.Label>
            <Form.Control as="select" name="units" value={entryData.units} onChange={handleChange}>
              <option value="g">g</option>
              <option value="ml">ml</option>
              <option value="servings">porciones</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">
            Añadir entrada
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ManualEntryModal;