import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const GenericMacrosModal = ({ show, handleClose, mealType, onSubmit }) => {
  const [macros, setMacros] = useState({ proteins: 0, carbohydrates: 0, fats: 0 });
  const [custom_name, setCustomName] = useState('');  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMacros(prev => ({ ...prev, [name]: parseFloat(value) || 0 }));
  };

  const handleNameChange = (e) => {
    setCustomName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(mealType, macros, custom_name);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Generic Macros for {mealType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Custom Name</Form.Label>
            <Form.Control type="text" name="name" value={custom_name} onChange={handleNameChange} placeholder="Enter a name for this entry" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Proteins (g)</Form.Label>
            <Form.Control type="number" name="proteins" value={macros.proteins} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Carbohydrates (g)</Form.Label>
            <Form.Control type="number" name="carbohydrates" value={macros.carbohydrates} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Fats (g)</Form.Label>
            <Form.Control type="number" name="fats" value={macros.fats} onChange={handleChange} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Add Macros
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default GenericMacrosModal;