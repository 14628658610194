// MacroProgressBars.js
import React from 'react';
// import { ProgressBar } from 'react-bootstrap';

const MacroProgressBars = ({ totalMacros, macrosGoals }) => {
  const calculatePercentage = (consumed, goal) => {
    return (consumed / goal) * 100;
  };

  const macros = [
    { name: 'Calories', consumed: totalMacros.calories, goal: macrosGoals.calories, color: 'info' },
    { name: 'Protein', consumed: totalMacros.proteins, goal: macrosGoals.proteins, color: 'danger' },
    { name: 'Carbs', consumed: totalMacros.carbohydrates, goal: macrosGoals.carbohydrates, color: 'success' },
    { name: 'Fat', consumed: totalMacros.fats, goal: macrosGoals.fats, color: 'warning' },
  ];

  const maxPercentage = Math.max(...macros.map(macro => calculatePercentage(macro.consumed, macro.goal)));
  const scaleFactor = maxPercentage > 100 ? 100 / maxPercentage : 1;

  return (
    <div className="macro-progress-bars">
      {macros.map((macro) => {
        const percentage = calculatePercentage(macro.consumed, macro.goal);
        const scaledPercentage = percentage * scaleFactor;
        const isExceeded = percentage > 100;

        return (
          <div key={macro.name} className="macro-bar mb-2">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <span>{macro.name}</span>
              <span>{macro.consumed.toFixed(1)} / {macro.goal.toFixed(1)}g</span>
            </div>
            <div className="progress-container">
              <div className="progress-background"></div>
              <div 
                className={`progress-fill bg-${macro.color}${isExceeded ? ' exceeded' : ''}`} 
                style={{width: `${scaledPercentage}%`}}
              ></div>
              <div className="progress-100-mark" style={{left: `${100 * scaleFactor}%`}}></div>
              <span className="progress-label">{`${Math.round(percentage)}%`}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MacroProgressBars;