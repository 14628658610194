import React from 'react';

function MealInput({ mealType, value, suggestion, onInputChange, onKeyDown }) {
  // Dividimos el valor actual por comas para manejar múltiples entradas
  const entries = value.split(',');
  const currentInput = entries[entries.length - 1].trim();

  // Obtenemos el texto antes de la entrada actual
  const valueBeforeCurrentInput = value.substring(0, value.lastIndexOf(currentInput));

  const handleKeyDown = (e) => {
    if (suggestion && (e.key === 'Tab' || e.key === 'ArrowRight')) {
      e.preventDefault();
      onKeyDown(mealType, e);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      // Emitimos un evento personalizado para el Enter
      onKeyDown(mealType, { ...e, isEnter: true });
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder={`Describe your meal to mr. fit.`}
        value={value}
        onChange={(e) => onInputChange(mealType, e)}
        onKeyDown={handleKeyDown}
        className="form-control"
      />
      {suggestion && (
        <div className="inline-suggestion">
          {valueBeforeCurrentInput}
          {currentInput}
          <span className="suggested-text">
            {suggestion}
          </span>
        </div>
      )}
    </div>
  );
}

export default MealInput;