import React, { useState, useEffect } from 'react';
import { apiFetchMealNames, apiUpdateMealNames } from '../api';

function Settings({ email }) {
  const [mealNames, setMealNames] = useState([]);

  useEffect(() => {
    const getMealNames = async () => {
      const names = await apiFetchMealNames(email);
      if (names) {
        setMealNames(names);
      }
    };

    getMealNames();
  }, [email]);

  const handleChange = (index, value) => {
    const newMealNames = [...mealNames];
    newMealNames[index] = value;
    setMealNames(newMealNames);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // prevents page from reloading
    await apiUpdateMealNames(email, mealNames);
    alert('Meal names updated successfully');
  };

  const handleAddMeal = () => {
    setMealNames([...mealNames, '']);
  };

  const handleRemoveMeal = (index) => {
    const newMealNames = mealNames.filter((_, i) => i !== index);
    setMealNames(newMealNames);
  };

  return (
    <div className="container mt-5">
      <h3>Meal Names</h3>
      <form onSubmit={handleSubmit}>
        {mealNames.map((name, index) => (
          <div key={index} className="row mb-3 align-items-center">
            <div className="col-md-2">
              <label htmlFor={`meal-${index}`} className="form-label">Meal {index + 1}</label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control"
                id={`meal-${index}`}
                value={name}
                onChange={(e) => handleChange(index, e.target.value)}
                required
              />
            </div>
            <div className="col-md-2">
              <button 
                type="button" 
                className="btn btn-sm btn-link action-button" 
                onClick={() => handleRemoveMeal(index)}
              >
                <i className="fas fa-trash action-icon"></i>
              </button>
            </div>
          </div>
        ))}
        <div className="row mb-3">
          <div className="col-md-12">
            <button type="button" className="btn btn-secondary" onClick={handleAddMeal}>
              Add Meal
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button type="submit" className="btn btn-primary">Save Meal Names</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Settings;